<template>
  <div class="container-fluid p-0">
    <div class="auth-bg-video-1 ">
      <video id="bgvid" poster="@/assets/images/pages/login/across-benlea-to-mount.jpg" playsinline="" autoplay="" muted=""
             loop="">
        <source src="@/assets/images/pages/login/auth-bg-2.mp4" type="video/mp4">
      </video>
      <div class="glassmorphism" >
      </div>
    </div>
    <div class="home-page-content">
      <div style ="padding:25px"></div>
      <HomeHeader
          :config = "config"
      ></HomeHeader>
      <div v-if="!config.isNavMenuHidden">
        <ul class="subtitle-nav-text"  >
          <div class ="product-nav-text-font" v-bind:style= "[config.isOption_1 ? {'color': config.activeColor} : {'color': config.disabledColor}]"
               @click="active_option_1()">
            企业文化
          </div>
          <div class ="product-nav-text-font"  >
            |
          </div>
          <div class ="product-nav-text-font" v-bind:style= "[config.isOption_2 ? {'color': config.activeColor} : {'color': config.disabledColor}]"
               @click="active_option_2">
            企业信息
          </div>
          <div class ="product-nav-text-font">
            |
          </div>
          <div class ="product-nav-text-font" v-bind:style= "[config.isOption_3 ? {'color': config.activeColor} : {'color': config.disabledColor}]"
               @click="active_option_3">
            联系我们
          </div>
        </ul>
      </div>
      <div v-if="config.isOption_1" style="position: relative; top: 5vh; left:2.5vw; width: 95%">
        <b-row>
          <!-- image top -->
          <b-col
              md="6"
              xl="4"
          >
            <b-card
                :img-src="require('@/assets/images/TS/DNL.jpg')"
                img-top
                img-alt="card img"
                title="Card title"
                class="mb-3"
            >
              <b-card-text>
                This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.
                This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.
                This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.
                This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.
                This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.
                This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.
                This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.
                This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.
                This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.
                This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.
                This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.






              </b-card-text>
              <b-card-text>
                <small class="text-muted">Last updated 3 mins ago</small>
              </b-card-text>
            </b-card>
          </b-col>


          <!-- img overlay -->

        </b-row>
        <b-row>
          <!-- image top -->
          <b-col
              md="6"
              xl="4"
          >
            <b-card
                :img-src="require('@/assets/images/TS/DNL_FACTORY.jpg')"
                img-top
                img-alt="card img"
                title="Card title"
                class="mb-3"
            >
              <b-card-text>
                This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.
              </b-card-text>
              <b-card-text>
                <small class="text-muted">Last updated 3 mins ago</small>
              </b-card-text>
            </b-card>
          </b-col>


          <!-- img overlay -->

        </b-row>
      </div>
      <div v-if="config.isOption_2" style="position: relative; top: 5vh; left:2.5vw; width: 95%">
        <b-row>
          <!-- image top -->
          <b-col
              md="6"
              xl="4"
          >
            <b-card
                :img-src="require('@/assets/images/TS/DNL_1.jpg')"
                img-top
                img-alt="card img"
                title="Card title"
                class="mb-3"
            >
              <b-card-text>
                This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.
                This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.
                This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.
                This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.
                This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.
                This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.
                This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.
                This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.
                This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.
                This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.
                This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.






              </b-card-text>
              <b-card-text>
                <small class="text-muted">Last updated 3 mins ago</small>
              </b-card-text>
            </b-card>
          </b-col>


          <!-- img overlay -->

        </b-row>
      </div>
      <div v-if="config.isOption_3" style="position: relative; top: 5vh; left:2.5vw; width: 95%">
        <b-row>
          <!-- image top -->
          <b-col
              md="6"
              xl="4"
          >
            <b-card
                :img-src="require('@/assets/images/TS/DNL.jpg')"
                img-top
                img-alt="card img"
                title="Card title"
                class="mb-3"
            >
              <b-card-text>
                This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.
                This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.
                This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.
                This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.
                This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.
                This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.
                This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.
                This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.
                This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.
                This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.
                This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.






              </b-card-text>
              <b-card-text>
                <small class="text-muted">Last updated 3 mins ago</small>
              </b-card-text>
            </b-card>
          </b-col>


          <!-- img overlay -->

        </b-row>
      </div>

      <div v-if="config.isOption_4" style="position: relative; top: 5vh">
        option 4
      </div>
    </div>
  </div>

</template>

<script>
import HomeHeader from '@/views/home-page-phone/components/HomeHeader.vue'
import {
  BRow, BCol, BCard, BCardText, BCardTitle,
} from 'bootstrap-vue'
export default {
  name: 'AboutPage',
  components: {
    BCard,
    BCardText,
    BCardTitle,
    BRow,
    BCol,
    HomeHeader
  },
  methods: {
    _isMobile(){
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    },
    active_option_1() {
      this.config.isOption_1 = true;
      this.config.isOption_2 = false;
      this.config.isOption_3 = false;
      this.config.isOption_4 = false;
    },
    active_option_2() {
      this.config.isOption_1 = false;
      this.config.isOption_2 = true;
      this.config.isOption_3 = false;
      this.config.isOption_4 = false;
    },
    active_option_3() {
      this.config.isOption_1 = false;
      this.config.isOption_2 = false;
      this.config.isOption_3 = true;
      this.config.isOption_4 = false;
    },
    active_option_4() {
      this.config.isOption_1 = false;
      this.config.isOption_2 = false;
      this.config.isOption_3 = false;
      this.config.isOption_4 = true;
    }
  },
  mounted: function() {
    if (this._isMobile()) {
      this.$router.replace('/phone/about');
    }else {
      this.$router.replace('/about');
    }
  },
  props: {
    msg: String,
  },

  data(){
    const config = {
      isNavMenuHidden : false,
      isSearchDisabled : true,
      homeColor :  "#FFFFFF",
      aboutColor: "#036EB8",
      productColor: "#FFFFFF",
      mySpaceColor: "#FFFFFF",
      activeColor: "#036EB8",
      disabledColor: "#FFFFFF",
      isOption_1: true,
      isOption_2: false,
      isOption_3: false,
      isOption_4: false,
    }
    return {
      config,
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

@import '@core/scss/vue/pages/page-auth.scss';
.product-nav{
  background: rgba(3, 110, 184, 0.2);
  backdrop-filter: blur(88.5px);
  text-align: right;
  display: flex;
  flex-direction: row-reverse;

}
.product-nav-text{
  display:flex;flex-direction: row;align-items: flex-start;
}
.product-nav-text-font{
  position: relative;
  width: 61.6px;
  height: 18px;
  top: 8px;

  font-family: 'Microsoft YaHei';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-align: center;


}

.subtitle-nav-text{
  display:flex;flex-direction: row;align-items: flex-start;
  position: relative;
  width: 100vw;
  height: 35px;
  left: 0px;
  top: 35px;

  background: rgba(3, 110, 184, 0.2);
}
.home-page-content{
  position: absolute;
  top: 0;
}
</style>